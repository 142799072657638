import * as React from "react"
import { connect } from "react-redux"
import * as sessionSelectors from "../../redux/slices/session/selectors"
import Layout from "../../components/layout"
import { toast } from "react-toastify"
import { navigate } from "gatsby"
import { PersonalityTestStyles } from "../../components/PersonalityTest/style"
import Button from "../../components/Reusable/Button"
import header from "../../images/tes-kepribadian/tes-kepribadian.png"

import {
  createPersonalityTest,
  getListPersonalityTest,
} from "../../services/personalityTest"
import { useEffect, useState } from "react"

const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
})

const mapDispatchToProps = {}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const PersonalityTestPage = ({ user }) => {
  const [status, setStatus] = useState()
  const [result, setResult] = useState()

  useEffect(async () => {
    if (user?.email) {
      await getListPersonalityTest().then(resp => {
        if (resp.data.results.length === 0) {
          setStatus(0)
        } else if (resp.data.results[0].finished === true) {
          setStatus(1)
          setResult(resp.data.results[0])
        } else {
          if (resp.data.results.length > 1) {
            setStatus(2)
            setResult(resp.data.results[1])
          } else {
            setStatus(3)
          }
        }
      })
    }
  }, [])

  const startPersonalityTest = () => {
    if (status === 1 || status === 0) {
      createPersonalityTest().then(resp =>
        navigate(`ongoing-test/${resp.data.id}/`)
      )
    } else {
      getListPersonalityTest().then(resp =>
        navigate(`ongoing-test/${resp.data.results[0].id}/`)
      )
    }
  }

  const login = () => {
    toast("Anda perlu login terlebih dahulu")
    setTimeout(() => {
      navigate("/login")
    }, 1000)
  }

  return (
    <Layout>
      <PersonalityTestStyles>
        <div className="header">
          <div className="title">
            Quiz 16 Kepribadian John Oldham <br />
            Mari Kenali Dirimu!{" "}
          </div>
          <img
            src={header}
            alt="header"
            style={{ width: "100%", zIndex: "10px" }}
          />
        </div>

        {result && (
          <div className="hasilTesSebelumnya">
            <div className="judul">Tipe Kepribadian Kamu</div>
            <div className="result">
              <img src={result?.result?.image} alt="puzzle" />
              <div className="resultDescription ">
                <div
                  className="titleResult"
                  style={{
                    textAlign: "center",
                    paddingBottom: "10px",
                    fontWeight: "bold",
                  }}
                >
                  {result?.result?.title}
                </div>
                <Button
                  to={`test-result/${result?.id}`}
                  className="mulai"
                  style={{
                    marginBottom: "10px",
                    marginTop: "5px",
                  }}
                >
                  Lihat Penjelasan
                </Button>
                <div className="textToLink" style={{ color: "#EFA51D" }}>
                  atau{" "}
                  <span onClick={startPersonalityTest} className="textLink">
                    {status === 1 && "ikuti kuisnya lagi!"}
                    {status === 2 && "lanjutkan kuisnya!"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}

        {user?.email ? (
          <div>
            {status === 0 && (
              <Button onClick={startPersonalityTest} className="mulai">
                ikuti kuisnya!
              </Button>
            )}
            {status === 3 && (
              <Button onClick={startPersonalityTest} className="mulai">
                lanjtkan kuisnya!
              </Button>
            )}
          </div>
        ) : (
          <Button onClick={login} className="mulai">
            Ayo ikuti kuisnya!
          </Button>
        )}

        <div className="box">
          <div className="content">
            Baca panduan pengisiannya, yuk! <br></br>
            <ol>
              <li>
                Gak ada jawaban yang benar atau salah. Isilah dengan jujur
                sesuai kepribadianmu.
              </li>
              <li>Santai aja, tes ini gak diberi waktu, kok.</li>
              <li>
                Cari tempat yang nyaman dan kondusif supaya kamu lebih fokus.
              </li>
              <li>
                Jika kamu keluar di tengah tes, seluruh proses tes akan tetap
                disimpan sehingga kamu dapat melanjutkan tesnya lagi.
              </li>
              <li>
                Hasil tes bisa kamu dapatkan setelah mengisi semua pertanyaan
                dengan lengkap.
              </li>
            </ol>
            <div styles={{ marginTop: "15px" }}>{"Selamat mengisi ya :)"}</div>
          </div>
        </div>
      </PersonalityTestStyles>
    </Layout>
  )
}

export default withConnect(PersonalityTestPage)
